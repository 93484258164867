* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.splash_screen {
  height: 100vh;
  background-image: url('./assets/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.splash_screen::before {
  background-color: #b6b6b6;
  content: "";
  height: 100%;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
}

.form_box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login_main_div {
  background-color: #FBF9FF;
  width: 600px;
  /* border-radius: 12px; */
  /* height: 605px; */
}

.login_div {
  padding-inline: 110px;
}

.input_box {
  box-shadow: 0px 5px 15px #00000021;
  border-radius: 9px;
  opacity: 1;
  height: 45px;
}

.auth_button {
  background-color: #000;
  height: 45px;
  border-radius: 12px;
  color: #fff;
  padding-inline: 115px;
  font-size: 1rem;
  border-radius: 8px;
}

.LinkStyle {
  text-decoration: none;
  color: #000;
  font-style: italic;
}

.otp_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.otp_input_container {
  margin-top: 0rem !important;
  margin: 0.8rem;
  padding: 1rem;
  width: 4rem !important;
  height: 4rem !important;
  font-size: 2rem;
  color: #1E1E1E;
  border: none !important;
  border-color: none !important;
  box-shadow: 0px 3px 7px #00000024;
  border-radius: 20px;
  opacity: 1;
}

/* layout css */

.header {
  background-color: #fff;
  height: 72px;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0px 4px 4px #0000000A;
  opacity: 1;
}

.header_content {
  width: 93%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_style {
  text-align: center;
  color: rgb(255, 255, 255);
  background: #f6f6f6;
  min-height: 100vh;
  overflow-y: auto;
  padding: 30px 30px;
  margin-left: 225px;
  position: relative;
  /* border: 1px solid red; */

}

.toggling_sider {
  box-shadow: 5px 0px 10px #2653A20D;
  min-width: 225px !important;
}

.home_mainDiv {
  box-shadow: 0px 10px 15px #0000000A;
  border-radius: 5px;
  opacity: 1;
  background-color: #ffffff;
  min-height: 700px;
}

.home_card {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #DFDFDF;
  border-radius: 20px;
  opacity: 1;
  min-height: 160px;
}

.refresh_antIcon {
  color: #142a43;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
}

.active {
  color: #181818;
  font-weight: bold;
  font-size: 1.2rem;
  font-weight: 600;
}

.unactive {
  font-size: 1.2rem;
  color: grey;
  cursor: pointer;
  font-weight: 600;
}

.common_button {
  border: 1px solid #000;
  background-color: transparent;
  height: 40px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 1rem;
}

.view_report {
  color: #196DDB;
  text-decoration: underline;
  font-weight: bold;
}

.selectBox {
  border: 1px solid #000;
  width: 120px;
  text-align: start;
  height: 40px;
  border-radius: 15px;
}

.selectBox .ant-select-selector .ant-select-selection-placeholder {
  color: #000 !important;
}

.view_select_box .ant-select-selector {
  border-bottom: 1px solid #d9d9d9 !important;
  background: #ffffff !important;
  border-radius: 0px !important;
}

.view_select_box.ant-select-single {
  height: 49px !important;
}

.filterIcon .ant-image .ant-image-img {
  width: 30px !important;
}

.common_table_btn {
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
}

.background_img {
  background-image: url('./assets/viewDummy.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 167px;
  width: 282px;
  border-radius: 7px;
}

.view_card {
  box-shadow: 0px 5px 15px #00000021;
  border-radius: 9px;
  opacity: 1;
  cursor: pointer;
}

.text_color {
  font-weight: 700;
}

.export_button {
  color: #F15A2B;
  border: 1px solid #F15A2B;
  border-radius: 15px;
  height: 40px;
  padding-inline: 30px;
}

.inspection_view_card {
  box-shadow: 0px 5px 15px #00000021;
  border-radius: 9px;
  opacity: 1;
}

.input_box_inspection_view {
  height: 3rem;
  border: none !important;
  border-bottom: 1px solid #cfc7c7 !important;
  background-color: transparent;
  box-shadow: none !important;
  border-radius: 0;
  padding: 0px 0px 11px;
  /* width: 100%; */
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label {
  padding: 0px 0 0px;
}

.user_profile {
  /* border: 2px dotted #EC1C24B3; */
  background-color: #EC1C240D;
  border-width: 3px;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  cursor: pointer;
}
.carousel_item {
  padding: 0 5px;
}
.inspection_view .ant-image .ant-image-img {
  width: 180px !important;
}