body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  --defaultLightColor: #EEEFF1;
  --defaultLightColor2: #f2d5d1;
  --defaultLightPink: #FFE3DF;
  --defaultGreenColor: #00AE2F --defaultGrayDark: #16242f;

}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: var(--defaultRedColor)
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--defaultLightColor2);
  border-radius: 2px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.ant-form-item .ant-form-item-explain-error {
  text-align: start;
}

.ant-menu-item-selected {
  background: #000 !important;
  color: #fff !important;
  font-weight: 600;
}

.ant-menu-inline .ant-menu-item {
  border-radius: initial !important;
  margin-inline: 0px !important;
}

.ant-menu-inline .ant-menu-item {
  background-color: #fff;
  /* color: #e4a79f; */
}

.ant-table-thead .ant-table-cell {
  background-color: var(--defaultLightColor) !important;
}

.ant-table-cell::before {
  background-color: transparent !important;
}

.ant-switch.ant-switch-checked {
  background: #00AE2F !important;
}

.ant-switch {
  background: var(--defaultRedColor);
}

.ant-breadcrumb .ant-breadcrumb-separator {
  font-size: 1.3rem;
}

/* .ant-modal-root .ant-modal-mask {
  background: #000;
  opacity: 0.85;
} */

.ant-pagination .ant-pagination-item-active {
  background-color: #000;
  color: var(--defaultLightColor);
  border: none;
}

.ant-collapse {
  background-color: var(--defaultLightColor2) !important;
  border: none !important;
  box-shadow: 0px 3px 4px 1px #dbdbdb;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: 2px dashed #142A42;
  height: 120px;
  width: 120px !important;

}

.ant-upload-list-item-container {
  height: 120px !important;
  width: 120px !important;
}

.imageUploader .ant-tooltip {
  display: none;
}

span.anticon.anticon-eye {
  display: none;
}

.ant-upload-list-item.ant-upload-list-item-error {
  border-color: var(--defaultLightPink) !important;
}

.ant-popover .ant-popover-inner {
  background-color: #eeeff1;
  padding-inline: 25px;
  margin-top: 5px;
}

.ant-modal .ant-modal-content {
  border-radius: 2px;
}

.ant-select .ant-select-arrow {
  color: #000;
}

.ant-image .ant-image-img {
  width: auto !important;
}

.ant-checkbox .ant-checkbox-inner {
  width: 25px !important;
  height: 24px !important;
  border: 1px solid #000;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000 !important;
}

.ant-checkbox .ant-checkbox-inner:after {
  height: 13.142857px !important;
}

.ant-checkbox-wrapper {
  font-size: 16px !important;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name{
  color: #000 !important
}